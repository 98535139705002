import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    getFirestore,
    doc,
    getDoc,
    setDoc,
    getDocs,
    collection,
} from "firebase/firestore";
import { getAuth, updateEmail, updatePassword } from "firebase/auth";
import "./Profile.css";

function Profile() {
    const [userData, setUserData] = useState({
        email: "",
        zipCode: "",
        plants: [],
    }); // Current user data
    const [availablePlants, setAvailablePlants] = useState([]); // All available plants
    const [editMode, setEditMode] = useState({
        email: false,
        password: false,
        zipCode: false,
        plants: false,
    }); // Toggle for edit modes
    const [formState, setFormState] = useState({
        newEmail: "",
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        zipCode: "",
        plants: [],
    }); // Temporary state for edits
    const navigate = useNavigate();
    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;

    useEffect(() => {
        // Fetch user profile data
        const fetchUserProfile = async () => {
            try {
                const userDoc = await getDoc(doc(db, "users", user.uid));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setUserData({
                        email: user.email,
                        zipCode: userData.zipCode || "",
                        plants: userData.plants || [],
                    });
                }
            } catch (error) {
                console.error("Error fetching user profile:", error);
            }
        };

        // Fetch all available plants
        const fetchAvailablePlants = async () => {
            try {
                const plantDocs = await getDocs(collection(db, "plants"));
                const plants = plantDocs.docs.map((doc) => ({
                    id: doc.id,
                    name: doc.data().name,
                }));
                setAvailablePlants(plants);
            } catch (error) {
                console.error("Error fetching plants:", error);
            }
        };

        fetchUserProfile();
        fetchAvailablePlants();
    }, [db, user]);

    // Handle input changes for editable fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState((prev) => ({ ...prev, [name]: value }));
    };

    // Toggle plant selection on click
    const togglePlantSelection = (plantName) => {
        setFormState((prev) => ({
            ...prev,
            plants: prev.plants.includes(plantName)
                ? prev.plants.filter((p) => p !== plantName)
                : [...prev.plants, plantName],
        }));
    };

    // Save updated email
    const updateEmailHandler = async () => {
        try {
            if (formState.newEmail) {
                await updateEmail(user, formState.newEmail);
                setUserData((prev) => ({ ...prev, email: formState.newEmail }));
                alert("Email updated successfully!");
            }
            setEditMode((prev) => ({ ...prev, email: false }));
        } catch (error) {
            console.error("Error updating email:", error);
        }
    };

    // Save updated password
    const updatePasswordHandler = async () => {
        try {
            if (formState.newPassword !== formState.confirmNewPassword) {
                alert("New passwords do not match!");
                return;
            }
            await updatePassword(user, formState.newPassword);
            alert("Password updated successfully!");
            setEditMode((prev) => ({ ...prev, password: false }));
        } catch (error) {
            console.error("Error updating password:", error);
        }
    };

    // Save updated zip code
    const updateZipCodeHandler = async () => {
        try {
            if (formState.zipCode) {
                await setDoc(doc(db, "users", user.uid), { zipCode: formState.zipCode }, { merge: true });
                setUserData((prev) => ({ ...prev, zipCode: formState.zipCode }));
                alert("Zip code updated successfully!");
            }
            setEditMode((prev) => ({ ...prev, zipCode: false }));
        } catch (error) {
            console.error("Error updating zip code:", error);
        }
    };

    // Save updated plants
    const updatePlantsHandler = async () => {
        try {
            if (formState.plants.length) {
                await setDoc(doc(db, "users", user.uid), { plants: formState.plants }, { merge: true });
                setUserData((prev) => ({ ...prev, plants: formState.plants }));
                alert("Plant selection updated successfully!");
            }
            setEditMode((prev) => ({ ...prev, plants: false }));
        } catch (error) {
            console.error("Error updating plants:", error);
        }
    };

    return (
        <div className="profile">
            <h2>Profile Settings</h2>

            {/* Email Section */}
            <div>
                <label>Email:</label>
                {!editMode.email ? (
                    <div>
                        {userData.email}{" "}
                        <button onClick={() => setEditMode((prev) => ({ ...prev, email: true }))}>
                            Change Email
                        </button>
                    </div>
                ) : (
                    <div>
                        <input
                            type="email"
                            name="newEmail"
                            value={formState.newEmail}
                            onChange={handleInputChange}
                        />
                        <button onClick={updateEmailHandler}>Save</button>
                    </div>
                )}
            </div>

            {/* Password Section */}
            <div>
                <label>Password:</label>
                {!editMode.password ? (
                    <button onClick={() => setEditMode((prev) => ({ ...prev, password: true }))}>
                        Change Password
                    </button>
                ) : (
                    <div>
                        <input
                            type="password"
                            name="newPassword"
                            placeholder="New Password"
                            onChange={handleInputChange}
                        />
                        <input
                            type="password"
                            name="confirmNewPassword"
                            placeholder="Confirm New Password"
                            onChange={handleInputChange}
                        />
                        <button onClick={updatePasswordHandler}>Save</button>
                    </div>
                )}
            </div>

            {/* Zip Code Section */}
            <div>
                <label>Zip Code:</label>
                {!editMode.zipCode ? (
                    <div>
                        {userData.zipCode}{" "}
                        <button onClick={() => setEditMode((prev) => ({ ...prev, zipCode: true }))}>
                            Change Zip Code
                        </button>
                    </div>
                ) : (
                    <div>
                        <input
                            type="text"
                            name="zipCode"
                            value={formState.zipCode}
                            onChange={handleInputChange}
                        />
                        <button onClick={updateZipCodeHandler}>Save</button>
                    </div>
                )}
            </div>

            {/* Plants Section */}
            <div>
                <label>Plants:</label>
                {!editMode.plants ? (
                    <div>
                        <ul className="plant-list">
                            {userData.plants.map((plant) => (
                                <li key={plant}>{plant}</li>
                            ))}
                        </ul>
                        <button onClick={() => setEditMode((prev) => ({ ...prev, plants: true }))}>
                            Update Plant Collection
                        </button>
                    </div>
                ) : (
                    <div>
                        <ul className="plant-list">
                            {availablePlants.map((plant) => (
                                <li
                                    key={plant.id}
                                    className={
                                        formState.plants.includes(plant.name)
                                            ? "selected-plant"
                                            : ""
                                    }
                                    onClick={() => togglePlantSelection(plant.name)}
                                >
                                    {plant.name}
                                </li>
                            ))}
                        </ul>
                        <button onClick={updatePlantsHandler}>Save</button>
                    </div>
                )}
            </div>

            <button onClick={() => navigate("/dashboard")}>Back to Dashboard</button>
        </div>
    );
}

export default Profile;
