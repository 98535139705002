import React, { useState, useEffect } from "react";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import axios from "axios";

function Dashboard() {
    const [pests, setPests] = useState([]);
    const [diseases, setDiseases] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const [dailyWeather, setDailyWeather] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const db = getFirestore();
    const auth = getAuth();
    const navigate = useNavigate();
    const fetchUserProfile = async () => {
        try {
            const userDoc = await getDoc(doc(db, "users", currentUser.uid));
            if (userDoc.exists()) {
                const profileData = userDoc.data();
                console.log("User profile fetched:", profileData);
                setUserProfile(profileData);
            } else {
                console.error("User profile not found");
                navigate("/register");
            }
        } catch (error) {
            console.error("Error fetching user profile:", error);
        }
    };

    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user); // Update the currentUser state
            } else {
                setCurrentUser(null); // Clear the state if user logs out
                navigate("/"); // Redirect to login if no user
            }
        });

        // Clean up the listener when the component unmounts
        return () => unsubscribe();
    }, [auth, navigate]);
    // Fetch user profile on load
    useEffect(() => {
        if (currentUser?.uid) {
            fetchUserProfile();
        }
    }, [db, currentUser, navigate]);



    useEffect(() => {

            console.log("Fetching weather with profile:", userProfile);
            fetchWeather();
            const interval = setInterval(fetchWeather, 24 * 60 * 60 * 1000); // Every 24 hours
            return () => clearInterval(interval);
    }, [userProfile]);

    useEffect(() => {
        if (userProfile) {
            fetchPredictions();
        }
    }, [userProfile]);

    const updateUserCoordinates = async (lat, lon) => {
        try {
            const userRef = doc(db, "users", currentUser.uid);
            await setDoc(userRef, { latitude: lat, longitude: lon }, { merge: true });
        } catch (error) {
            console.error("Error updating user coordinates:", error);
        }
    };

    const fetchWeather = async () => {
        if (!userProfile) {
            console.error("User profile is missing or missing zip code!");
            return;
        }

        try {
            const userRef = doc(db, "users", currentUser.uid);
            const userSnapshot = await getDoc(userRef);

            if (userSnapshot.exists()) {
                const { weatherData, weatherTimestamp } = userSnapshot.data();
                const now = new Date();
                const lastUpdated = weatherTimestamp ? new Date(weatherTimestamp) : null;

                // Use cached weather data if it's less than 24 hours old
                if (lastUpdated && now - lastUpdated < 24 * 60 * 60 * 1000) {
                    setDailyWeather(weatherData.dailyWeather || []);
                    console.log("Using cached weather data:", weatherData);
                    return;
                }
            }

            // Fetch fresh weather data
            const response = await axios.post("https://backend.leaf-lab.com/api/forecast", {
                location: userProfile.zipCode,
            });
            const freshWeatherData = response.data;

            // Save to Firestore
            await setDoc(userRef, {
                weatherData: freshWeatherData,
                weatherTimestamp: new Date().toISOString(),
            }, { merge: true });

            setDailyWeather(freshWeatherData.dailyWeather || []);
            console.log("Fetched new weather data:", freshWeatherData);
        } catch (error) {
            console.error("Error fetching weather:", error);
        }
    };


    const fetchPredictions = async () => {
        if (!userProfile) {
            alert("User profile is missing! Error happening here!");
            return;
        }

        setLoading(true);
        try {
            // Step 1: Geocode zip code to get lat/lon
            const geocodeResponse = await axios.get(
                "https://maps.googleapis.com/maps/api/geocode/json",
                {
                    params: {
                        address: userProfile.zipCode,
                        key: process.env.REACT_APP_GOOGLE_API_KEY,
                    },
                }
            );

            if (geocodeResponse.data.status !== "OK") {
                throw new Error("Geocoding failed: " + geocodeResponse.data.status);
            }

            const { lat, lng } = geocodeResponse.data.results[0].geometry.location;

            // Step 2: Save lat/lon to Firestore
            await updateUserCoordinates(lat, lng);

            // Step 3: Call backend to fetch predictions
            const response = await axios.post("https://backend.leaf-lab.com/api/predict", {
                uid: currentUser.uid,
                lat: lat,
                lon: lng,
            });

            setPests(response.data.pests || []);
            setDiseases(response.data.diseases || []);

            console.log("API Response: ", response.data);
        } catch (error) {
            console.error("Error fetching predictions:", error);
        }
        setLoading(false);
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate("/");
        } catch (error) {
            console.error("Error logging out:", error);
        }
    };

    const navigateToProfile = () => {
        navigate("/profile");
    };

    const showDetails = (item) => {
        console.log("Modal Clicked Item: ", item);
        setSelectedItem(item);
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
        setSelectedItem(null);
    };

    return (
        <div className="App">
            <header className="App-header">
                <h1>Leaf Lab</h1>
                <div>
                    <button onClick={navigateToProfile} className="profile-btn">Profile</button>
                    <button onClick={handleLogout} className="logout-btn">Logout</button>
                </div>
            </header>

            <main className="Dashboard-main">
                <div className="weather-section">
                    <h2>5-Day Weather Forecast</h2>
                    {dailyWeather.length > 0 ? (
                        <div className="weather-boxes">
                            {dailyWeather.map((day, index) => (
                                <div key={index} className="weather-box">
                                    <p><strong>{day.date}</strong></p>
                                    <p>{day.shortForecast}</p>
                                    <p>High: {day.temperatureHigh}°{day.temperatureUnit}</p>
                                    <p>Low: {day.temperatureLow}°{day.temperatureUnit}</p>
                                    <p>Humidity: {day.humidityLow}% - {day.humidityHigh}%</p>
                                    <p>Precipitation: {day.precipitation}%</p>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>Loading weather data...</p>
                    )}
                </div>
                <div className="prediction-section">
                    <h2>Pests and Diseases</h2>
                    <div className="results">
                        <h3>Pests</h3>
                        {pests.length > 0 ? (
                            pests.map((pest) => (
                                <div
                                    key={pest.pestName}
                                    className="prediction-item"
                                    onClick={() => showDetails(pest)}
                                >
                                    <strong>{pest.pestName}</strong>: {pest.plants.join(", ")}
                                </div>
                            ))
                        ) : (
                            <p>No pests predicted</p>
                        )}

                        <h3>Diseases</h3>
                        {diseases.length > 0 ? (
                            diseases.map((disease) => (
                                <div
                                    key={disease.diseaseName}
                                    className="prediction-item"
                                    onClick={() => showDetails(disease)}
                                >
                                    <strong>{disease.diseaseName}</strong>: {disease.plants.join(", ")}
                                </div>
                            ))
                        ) : (
                            <p>No diseases predicted</p>
                        )}
                    </div>
                    <button className="fetch-btn" onClick={fetchPredictions} disabled={loading}>
                        {loading ? "Fetching..." : "Fetch Predictions"}
                    </button>
                </div>
            </main>


            {modalVisible && selectedItem && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>{selectedItem.pestName || selectedItem.diseaseName}</h3>
                        <p>Symptoms: {selectedItem.symptoms || "N/A"}</p>
                        <p>Treatment: {selectedItem.controlMethods || "N/A"}</p>
                        <button onClick={closeModal}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Dashboard;
