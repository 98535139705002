import React, { useState } from 'react';
import Modal from 'react-modal';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import './App.css';
import { auth, db } from './firebase';  // Import Firebase auth and Firestore from firebase.js

import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import Dashboard from "./Dashboard";
import Profile from "./Profile";

Modal.setAppElement('#root');

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<AppContent />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </Router>
  );
}

function AppContent() {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [formState, setFormState] = useState({
    email: '',
    password: '',
    name: '',
    confirmPassword: '',
    zipCode: '',
  });

  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Open and close modal handlers
  const openLoginModal = () => {
    setIsRegisterModalOpen(false);
    setSuccessMessage('');
    setIsLoginModalOpen(true);
  };
  const closeLoginModal = () => setIsLoginModalOpen(false);
  const openRegisterModal = () => {
    closeLoginModal();
    setError('');
    setIsRegisterModalOpen(true);
  };
  const closeRegisterModal = () => setIsRegisterModalOpen(false);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  // Handle Login form submission
  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, formState.email, formState.password);
      closeLoginModal();
      navigate('/dashboard');
    } catch (err) {
      console.error('Login Error:', err.message);
      setError('Login failed. Please check your credentials.');
    }
  };

  // Handle Registration form submission
  const handleRegisterSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (formState.password !== formState.confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    if (!/^\d{5}$/.test(formState.zipCode)) {
        setError('Enter a valid 5-digit Zip Code.');
        return;
    }

    try {
      // Create user with Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, formState.email, formState.password);
      const user = userCredential.user;

      // Store additional user info in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        name: formState.name,
        email: formState.email,
        zipCode: formState.zipCode,
      });

      // Show success message and redirect to login modal
      setSuccessMessage('Registration successful! Please log in.');
      closeRegisterModal();
      openLoginModal();
    } catch (err) {
      console.error('Registration Error:', err.message);
      setError('Registration failed. Please try again.');
    }
  };

  // Handle logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (err) {
      console.error('Logout Error:', err.message);
    }
  };

  return (
      <div className="App">
        <header className="App-header">
          <div className="App-name">Leaf Lab</div>
          <p>Helping you grow a healthier, greener garden.</p>
          <button className="App-login-button" onClick={openLoginModal}>
            Log In
          </button>
        </header>

        <div className="App-description">
        <p>
            Welcome to Leaf Lab! Monitor your plants' health, predict pests and diseases, and keep your garden
            thriving with our easy-to-use tools.
          </p>
        </div>


        <footer className="App-footer">
          <p>&copy; 2024 Leaf Lab | Helping Gardens Thrive</p>
        </footer>

        {/* Success Message */}
        {successMessage && <p className="success-message">{successMessage}</p>}

        {/* Login Modal */}
        <Modal
            isOpen={isLoginModalOpen}
            onRequestClose={closeLoginModal}
            contentLabel="Login Modal"
            className="modal"
            overlayClassName="overlay"
        >
          <h2>Login</h2>
          <form onSubmit={handleLoginSubmit}>
            <input
                type="email"
                name="email"
                placeholder="Email"
                value={formState.email}
                onChange={handleChange}
                required
            />
            <input
                type="password"
                name="password"
                placeholder="Password"
                value={formState.password}
                onChange={handleChange}
                required
            />
            <button type="submit">Log In</button>
            {error && <p className="error-message">{error}</p>}
          </form>
          <p>
            Don't have an account?{' '}
            <button className="link-button" onClick={openRegisterModal}>
              Sign Up
            </button>
          </p>
        </Modal>

        {/* Register Modal */}
        <Modal
            isOpen={isRegisterModalOpen}
            onRequestClose={closeRegisterModal}
            contentLabel="Register Modal"
            className="modal"
            overlayClassName="overlay"
        >
          <h2>Register</h2>
          <form onSubmit={handleRegisterSubmit}>
            <input
                type="text"
                name="name"
                placeholder="Name"
                value={formState.name}
                onChange={handleChange}
                required
            />
            <input
                type="email"
                name="email"
                placeholder="Email"
                value={formState.email}
                onChange={handleChange}
                required
            />
            <input
                type="password"
                name="password"
                placeholder="Password"
                value={formState.password}
                onChange={handleChange}
                required
            />
            <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={formState.confirmPassword}
                onChange={handleChange}
                required
            />
            <input
                type="text"
                name="zipCode"
                placeholder="Enter 5-digit Zip Code"
                value={formState.zipCode}
                onChange={handleChange}
                required
            />
            <button type="submit">Sign Up</button>
            {error && <p className="error-message">{error}</p>}
          </form>
        </Modal>
      </div>
  );
}

export default App;
