// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDPeHuEaYJYLDQC-FOoNmcjCs5xdl0StRg",
    authDomain: "leaf-lab-a0c9b.firebaseapp.com",
    projectId: "leaf-lab-a0c9b",
    storageBucket: "leaf-lab-a0c9b.firebasestorage.app",
    messagingSenderId: "27737530325",
    appId: "1:27737530325:web:5cf85af392f1e37ea9a75c",
    measurementId: "G-VL5F28PC64"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
export {app, db, auth };